@import "variables";
@import "framework/framework";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
    font-family: 'Bebas';
    src: url('../../dist/font/BebasNeue-Regular.woff2') format('woff2'),
        url('../../dist/font/BebasNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../dist/font/NeueHelveticaStd-59Cm.woff2') format('woff2'),
        url('../../dist/font/NeueHelveticaStd-59Cm.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Bebas';
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

p,h2,h3,h4,ul{
	margin: 0;
	font-weight: 400;
}

.social{
	@include flex(row,center,center);
	gap: 10px;
	a{
		font-size: 20px;
		color: $white;
	}
}

.split{
	@include flex(column,initial,initial);
	@include tablet-up{
		@include flex(row,initial,initial);
		.side{
			flex: 1;
		}
	}
}

.btn{
	display: inline-block;
	background-color: transparent;
	border: solid 1px transparent;
	text-transform: uppercase;
	padding: 10px 30px;
	font-size: 14px;
	@include tablet-up{
		font-size: 18px;
	}
	&.btn-white-outline{
		color: $white;
		border-color: $white;
		@include hover-focus{
			background-color: $white;
			color: $black;
		}
	}
	&.btn-link{
		position: relative;
		padding: 0;
		color: $white;
		&::before{
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 2px;
			width: 100%;
			transition: all .4s;
			background-color: $white;
		}
		span{
			display: block;
			position: relative;
			padding: 4px 2px 2px;
		}
		@include hover-focus{
			color: $black;
			&::before{
				height: 100%;
			}
		}
	}
}

.note{
	text-transform: uppercase;
	font-family: sans-serif;
	font-size: rem(10);
	margin: 1em auto;
	width: 100%;
	max-width: rem(450);
}

form{
	overflow: hidden;
	label{
		position: absolute;
		left: -9999999px;
	}
	input,button{
		background-color: transparent;
		color: $white;
		border: solid 1px $white;
		text-transform: uppercase;
		padding: 10px;
		font-size: 16px;
		display: block;
		&::placeholder{
			color: $white;
		}
		@include tablet-up{
			font-size: 18px;
			padding: 10px 15px;
		}
	}
	.form-row{
		@include flex(row,center,initial);
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.content{
		position: relative;
	}
}

.section-heading{
	text-align: center;
	font-size: 40px;
	text-transform: uppercase;
	color: $white;
	margin-bottom: 30px;
}

.hide{
	display: none !important;
}

.menu-trigger{
	order: 2;
	display: block;
	border: none;
	background-color: transparent;
	border: none;
	border-style: none;
	border: solid 2px transparent;
	border-top-color: $white;
	border-bottom-color: $white;
	position: relative;
	width: 30px;
	height: 20px;
	transition: all .4s;
	&:before,&::after{
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		top: calc(50% - 1px);
		left: 0;
		position: absolute;
		background-color: $white;
		transition: all .4s;
	}
	&.close{
		border-color: transparent;
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 9999999999;
		&::after{
			transform: rotate(45deg);
		}
		&::before{
			transform: rotate(-45deg);
		}
	}
}

header {
	.top{
		padding: 10px 0;
		border-bottom: solid 1px $white;
		.container{
			@include flex(row,space-between,center);
			h2{
				font-size: 37px;
			}
		}
		&.sticky{
			position: fixed;
			top: 0;
			left: 0;
			transition: all .4s;
			z-index: 99999999;
			width: 100%;
			background-color: $black;
		}
	}

	.main{
		z-index: 999999999;
		position: fixed;
			top: 0;
			left: -100%;
		transition: all .4s;
		width: 100%;
		height: 100%;
		background-color: $black;
		background-size: cover;
		background-position: left top;
		background-repeat: no-repeat;
		.wrapper{
			padding: 30px 0;
			&:first-child{
				border-bottom: solid 1px $white;
				.nav{
					list-style: none;
					padding: 0;
					li{
						a{
							font-size: 40px;
							display: block;
							text-transform: uppercase;
							text-align: center;
							padding: 20px;
						}
					}
				}
			}
			&:last-child{
				.newsletter{
					margin-bottom: 20px;
					form{
						text-align: center;
						h2{
							text-transform: uppercase;
							font-size: 32px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&.active{
			left: 0;
		}
	}

	@include tablet-up{
		.main{
			border-right: solid 1px $white;
			left: 0;
			max-width: 340px;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				width: 2px;
			}
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			}
			&::-webkit-scrollbar-thumb {
				background-color: white;
				outline: none;
			}
			.wrapper{
				&:first-child{
					h2{
						font-size: 70px;
						text-align: center;
						margin-bottom: 30px;
					}
					.nav{
						li{
							padding: 10px;
							a{
								padding: 0;
								margin: 0 auto;
								border-bottom: solid 2px transparent;
								max-width: max-content;
								line-height: 1;
								font-size: 35px;
								@include hover-focus{
									border-bottom-color: $white;
								}
								&.active{
									border-bottom-color: $white;
								}
							}
						}
					}
				}
			}
		}	
	}
}

main {

	#hero{
		border-bottom: solid 1px $white; 
		.bg{
			object-position: top center; 
			@include tablet-down{
			}
		}
		.content{
			filter: drop-shadow(0 0 10px #000a);
			min-height: 100vh;
			padding-bottom: 88px;
			@include flex(column,flex-end,initial);
			&::before{
				content: "";
				background: transparent linear-gradient(180deg, #08080800 0%, #080808 100%) 0% 0% no-repeat padding-box;
				position: absolute;
					bottom: 0;
					left: 0;
				width: 100%;
				height: 100%;
				height: 476px;
			}
			.container{
				position: relative;
				text-align: center;
				h2{
					font-size: 53px;
					margin-bottom: 10px;
				}
				.btn{
					font-size: 22px;
				}
			}
		}
		@include tablet-up{
			border-bottom: none;
			.content{
				.container{
					h2{
						font-size: 170px;
						margin-bottom: 0;
					}
					.btn{
						font-size: 32px;
					}
				}
			}
		}
	}

	#music{
		border-bottom: solid 1px $white;
		padding: 40px 0;
		.container{
			text-align: center;
			.slider-music{
				padding: 0 30px;
				margin-bottom: 30px;
				.music-item{
					text-align: center;
					a{
						&:first-child{
							display: block;
							margin-bottom: 10px;
							img{
								width: 100%;
							}
						}
						&:last-child{
							font-size: 16px;
						}
					}
				}
				.slick-arrow{
					position: absolute;
					top: calc(50% - 10px);
					font-size: 20px;
					background-color: transparent;
					color: $white;
					border: none;
					padding: 0;
					cursor: pointer;
					z-index: 99;
					&.slick-prev{
						left: 0;
					}
					&.slick-next{
						right: 0;
					}
					i{
						font-weight: 400;
					}
				}
			}
			>.btn{
				font-size: 22px;
			}
		}
		@include tablet-up{
			.container{
				padding-bottom: 88px;
				.slider-music{
					display: grid;
					gap: 20px;
					grid-template-columns: 1fr 1fr 1fr;
					.music-item{
						a{
							&:first-child{
								position: relative;
								.play{
									transition: all .4s;
									opacity: 0;
									position: absolute;
										top: 0;
										left: 0;
									width: 100%;
									height: 100%;
									@include flex(column,center,center);
									background-color: hsla(0, 0%, 0%, 0.50);
									i{
										@include flex(column,center,center);
										border: solid 3px $white;
										width: 70px;
										height: 70px;
										border-radius: 70px;
										color: $white;
										font-size: 25px;
										&::before{
											transform: translateX(2px);
										}
									}
								}
								@include hover-focus{
									.play{
										opacity: 1;
									}
								}
							}
							&:last-child{
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}

	#videos{
		padding: 40px 0;
		border-bottom: solid 1px $white;
		.container{
			padding: 0;
			text-align: center;
			>.btn{
				font-size: 22px;
			}
			.wrapper{
				margin-bottom: 30px;
				.video{
					display: none;
					>a{
						video,img{
							width: 100%;
						}
					}
					.bottom{
						border-top: solid 2px $white;
						border-bottom: solid 1px $white;
						padding: 15px;
						text-align: center;
						display: block;
						.btn{
							font-size: 16px;
						}
					}
					&:nth-child(-n + 4){
						display: block;
					}
				}
			}
		}
		&.all{
			.container{
				.wrapper{
					.video{
						display: block;
					}
				}
			}
		}
		@include tablet-up{
			padding: 0;
			.container{
				padding-bottom: 88px;
				.wrapper{
					.video{
						position: relative;
						>a{
							video,img{
								height: 440px;
								width: 100%;
								object-fit: cover;
							}
						}
						.bottom{
							pointer-events: none;
							opacity: 0;
							transition: all .4s;
							padding: 0;
							border: none;
							position: absolute;
								top: 0;
								left: 0;
							width: 100%;
							height: 100%;
							.btn{
								font-size: 52px;
								@include flex(column,center,center);
								width: 100%;
								height: 100%;
								&::before{
									display: none;
									span{
										padding: 0;
									}
								}
								@include hover-focus{
									color: $white;
									span{
										color: $white;
									}
								}
							}
						}
						@include hover-focus{
							.bottom{
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	#tour{
		border-bottom: solid 1px $white;
		padding: 40px 0;
		.container{
			text-align: center;
			>.btn{
				font-size: 22px
			}
			#events{
				@include flex(column,initial,initial);
				gap: 30px;
				// margin-bottom: 40px;
				.event{
					text-align: left;
					@include flex(row,space-between,center);
					gap: 20px;
					.event-date{
						flex: 1;
						max-width: max-content;
						min-width: 60px;
						font-size: 19px;
					}
					.event-info{
						flex: 1;
						font-size: 14px;
						font-family: 'Helvetica';
						text-transform: uppercase;
						line-height: 1.4;
					}
					.event-links{
						flex: 1;
						max-width: max-content;
					}
				}
			}
		}
		@include tablet-up{
			border-bottom: none;
			padding-bottom: 88px;
			.container{
				min-height: calc(100vh - 168px);
				@include flex(column,center,initial);
				>.btn{
					font-size: 32px;
				}
				#events{
					max-width: 600px;
					margin: 0 auto 30px;
					.event{
						gap: 60px;
						.event-date{
							font-size: 39px;
							max-width: 130px;
						}
						.event-info{
							font-size: 24px;
							line-height: 1.2;
							flex: 2;
						}
						.event-links{
							.btn{
								font-size: 22px;
								max-width: 100%;
							}
						}
					}
				}
			}
		}
	}

	#bio{
		border-bottom: solid 1px $white;
		.bg{
			display: none;
		}
		.content{
			padding: 40px 0;
			.container{
				.bio{
					p{
						margin-bottom: 30px;
						font-size: 12px;
						font-family: 'Helvetica';
						line-height: 1.6;
						letter-spacing: 0.2px;
						text-transform: uppercase;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
		@include tablet-up{
			border-bottom: none;
			.bg{
				display: block;
				object-position: top left;
			}
			.content{
				&::before{
					content: "";
					background: transparent linear-gradient(270deg, #08080800 0%, #080808 100%) 0% 0% no-repeat padding-box;
					position: absolute;
						bottom: 0;
						left: 0;
					height: 100%;
					width: 476px;
				}
				min-height: 100vh;
				.container{
					padding:  0 30px;
					position: relative;
					padding-bottom: 88px;
					.bio{
						max-width: 600px;
						p{
							font-size: 18px;
						}
					}
				}
			}
		}
	}

	#merch{
		padding: 40px 0;
		.container{
			text-align: center;
			h3{
				font-size: 71px;
			}
		}
		@include tablet-up{
			.container{
				height: calc(100vh - 168px);
				@include flex(column,center,center);
				h3{
					font-size: 170px;
				}
			}
		}
	}

	@include tablet-up{
		padding-left: 340px;
		section{
			display: none;
			&.active{
				display: block;
			}
		}
	}
}

footer {
	padding: 30px 0;
	border-top: solid 1px $white;
	.container{
		.newsletter{
			h2{
				font-size: 32px;
				margin-bottom: 10px;
				text-align: center;
			}
		}
		.social{
			margin: 20px 0;
		}
		.copyright{
			text-align: center;
			img{
				display: block;
				height: 12px;
				margin: 0 auto;
			}
			p{
				display: inline-block;
				font-size: 8px;
				line-height: 1.4;
				font-family: 'Helvetica';
			}
		}
	}
	@include tablet-up{
		padding-left: 340px;
		border-top: none;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
}

.popup {
	background-color: $black;
	padding: rem(30);
	max-width: rem(400);
	color: $white;
	margin: 0 auto;
	position: relative;
	font-size: rem(22); 
	text-align: center;

	.mfp-close {
		color: $white;
	}

	form {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: rem(30);
		flex-wrap: wrap;

		p {
			flex-basis: 100%;
			font-size: rem(22);
			font-weight: bold;
			text-align: center;
		}
		input {
			flex: 1;
			border: none;
			border-bottom: 1px solid $white;
		}
		button{
			border: 1px solid $white;
		}
	}
}